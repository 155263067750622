import $ from 'jquery'

const action = $('#filter-box').attr('action')

$(document).on('click', '#filter-btn', function () {
  $('#filter-box').removeClass('hidden')
})

$(document).on('click', '#filter-btn-close', function () {
  $('#filter-box').addClass('hidden')
})

let initCalled = false
function init() {
  if (initCalled) return
  initCalled = true

  window.addEventListener('popstate', function (event) {
    const queryString = new URLSearchParams(window.location.search).toString()
    const url = `${action}?${queryString}`
    jQueryRefresh(url)
  })
}

window.onFormChange = function (event) {
  init()
  const queryString = new URLSearchParams(new FormData(event.currentTarget)).toString()
  const url = `${action}?${queryString}`
  const isVisible = !$('#filter-box').hasClass('hidden')
  jQueryRefresh(url, function () {
    if (isVisible) {
      $('#filter-box').removeClass('hidden')
    }
  })
  window.history.pushState(undefined, '', url)
  event.preventDefault()
}

function jQueryRefresh(url, callback) {
  $.get(url).then(text => {
    const html = $(text)
    $('#page').replaceWith(html.find('#page'))
    document.title = html.filter('title').text()
    scrollReveal()
    callback && callback()
  })
}
