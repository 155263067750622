// Import jQuery
import $ from 'jquery';

// Function to toggle modal visibility
const toggleModal = (buttonSelector, modalSelector, contentSelector = null) => {
  if ($(modalSelector).length && $(buttonSelector).length) {
    $(buttonSelector).click(function () {
      $('body').toggleClass('overflow-hidden');
      $(modalSelector).toggleClass('hidden');

      // Hide all video contents first, if contentSelector is provided
      if (contentSelector) {
        $('[id^="video-content"]').hide();
        $(contentSelector).show();
      }
    });
  }
};

// Map buttons to their respective modals and content
const mapping = {
  '#video-1': { modal: '#modal', content: '#video-content-1' },
};

// Attach click events to buttons based on the mapping
for (let button in mapping) {
  const { modal, content } = mapping[button];
  toggleModal(button, modal, content);
}

// Function to close the modal
$('#modal-close').click(function() {
  $('body').removeClass('overflow-hidden');
  $('#modal').addClass('hidden');
});

// Example usage of toggleModal for different buttons and modals
const additionalMapping = {
  '.offer-btn': '#offer',
  '#offer-close': '#offer',
};

// Attach click events for additional modals
for (let button in additionalMapping) {
  toggleModal(button, additionalMapping[button]);
}

// Track if the exit intent modal has been shown
let modalShown = localStorage.getItem('modalShown') === 'true';

function showModal() {
    if (!modalShown) {
        $('#exitIntent').removeClass('hidden');
        modalShown = true;
        localStorage.setItem('modalShown', 'true');
    }
}

function closeModal() {
    $('#exitIntent').addClass('hidden');
}

$('#exitIntent-close').click(closeModal);

document.addEventListener('mouseout', function(event) {
    if (event.clientY <= 0) {
        showModal();
    }
});
