import $ from 'jquery'

// Posluchač pro prvky s atributem data-target
$('[data-target]').on('click', function () {
  var targetId = $(this).data('target'); // Získá ID kontaktního divu
  $('.contact-section').addClass('hidden'); // Skryje všechny kontaktní sekce

  if (targetId) {
    $('#' + targetId).removeClass('hidden'); // Zobrazí pouze vybranou kontaktní sekci
  }

  $('body').addClass('overflow-hidden h-full w-full');
  $('#modal').removeClass('hidden').addClass('block'); // Zobrazí modální okno
});

// Posluchač pro prvky s třídou modal
$('.modal').on('click', function () {
  $('body').addClass('overflow-hidden h-full w-full');
  $('#modal').removeClass('hidden').addClass('block'); // Zobrazí modální okno
});

// Posluchač pro zavření modálního okna
$('.modal--close').on('click', function () {
  $('#modal').removeClass('block').addClass('hidden');
  $('body').removeClass('overflow-hidden h-full w-full');
  $('.contact-section').addClass('hidden'); // Skryje všechny kontaktní sekce
});




$('#menu-box').on('click', function () {
  $('body').toggleClass('body--hidden')
  $('#header').toggleClass('header--open')
})
